.App {
  text-align: center;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.skiptranslate.goog-te-gadget {
  color: #005e85!important;
}

.csv-reader-input {
  display: none!important;
}

.skiptranslate.goog-te-gadget > span {
  display: none!important;
}

/* Start Carousel Stuyling */
.carousel-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.carousel-content-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carousel-content {
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
    display: none;
}

.postponed-count {
  color: #fff;
  background-color: #c00000;
  border-radius: 50%;
  float: right;
  padding: 2px 10px;
}

.carousel-content > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

/*.left-arrow, .right-arrow {*/
/*    position: absolute;*/
/*    z-index: 1;*/
/*    top: 50%;*/
/*    transform: translateY(-50%);*/
/*    width: 48px;*/
/*    height: 48px;*/
/*    border-radius: 24px;*/
/*    background-color: white;*/
/*    border: none;*/
/*}*/
.left-arrow, .right-arrow {
  position: absolute;
  z-index: 5;
  top: 50%;
  transform: translateY(-50%);
  /*width: 5%;*/
  /*height: 32%;*/
  width: 4em;
  height: 4em;
  border-radius: 50%;
  background-color: white;
  border: none;
}

.left-arrow:focus, .left-arrow:hover, .left-arrow:active, .right-arrow:focus, .right-arrow:hover, .right-arrow:active {
  border: none;
  outline: none;
}

.left-arrow {
    left: 0;
    top: 60px;
}

.right-arrow {
    right: 0;
    top: 60px;
}

.carousel-content.show-2 > * {
  width: 50%;
}

.carousel-content.show-3 > * {
  width: calc(100% / 3);
}

.carousel-content.show-4 > * {
  width: calc(100% / 4);
}

.carousel-content.show-5 > * {
  width: calc(100% / 5);
}

/* End Carousel */

body {
  top: 0px !important;
}

html, body
{
  height: 100vh;
}
@media only screen and (max-width: 768px) and (orientation:portrait) {
  html, body {
    moz-transform: scale(0.75);
    zoom: 75%;
  }

  .pb-50 {
    padding-bottom: 102px !important;
  }
  .dropdown-toggle.menu-styles.nav-link.pill-menu.dropdown.nav-item::after{
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: normal;
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }
  .dropdown-toggle.menu-styles.nav-link.dropdown.nav-item::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: normal;
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }
  .dropdown-item {
    display: flex;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #fff !important;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    justify-content: center;
  }
  .dropdown-menu.show {
    background-color: #005e85 !important;
    padding: 0 0 !important;
    height: auto !important;
    width: auto !important;
    align-items: center !important;
    border: none !important;
  }
  .dropdown-item {
    color: #fff !important;
    align-items: self-start !important;
  }
  .dropdown-item:hover, .dropdown-item:active, .dropdown-item:focus {
    color: #fff!important;
    background-color: transparent!important;
    box-shadow: 0px 0px 0px transparent!important;
    border-color: transparent!important;
  }
  .nav-link {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
  }

  .nav-item.active .nav-link { color: #2fa753!important;
    font-weight: 600;
  }
  .pill-menu {
    color: #fff!important;
    background-color: #005e85!important;
    border-radius: 21px;
    border-color: #005e85!important;
  }
  .pill-menu:hover, .pill-menu:active, .pill-menu:focus {
    color: #fff!important;
    background-color: #005e85!important;
  }
}
@media only screen and (max-width: 991px) and (orientation:landscape) {
  html, body {
    moz-transform: scale(0.75);
    zoom: 75%;
  }
  .pb-50 {
    padding-bottom: 102px !important;
  }
  .dropdown-toggle.menu-styles.nav-link.pill-menu.dropdown.nav-item::after{
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: normal;
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }
  .dropdown-toggle.menu-styles.nav-link.dropdown.nav-item::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: normal;
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }
  .dropdown-item {
    display: flex;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #fff !important;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    justify-content: center;
  }
  .dropdown-menu.show {
    background-color: #005e85 !important;
    padding: 0 0 !important;
    height: auto !important;
    width: auto !important;
    align-items: center !important;
    border: none !important;
  }
  .dropdown-item {
    color: #fff !important;
    align-items: self-start !important;
  }
  .dropdown-item:hover, .dropdown-item:active, .dropdown-item:focus {
    color: #fff!important;
    background-color: transparent!important;
    box-shadow: 0px 0px 0px transparent!important;
    border-color: transparent!important;
  }
  .nav-link {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
  }

  .nav-item.active .nav-link { color: #2fa753!important;
    font-weight: 600;
  }
  .pill-menu {
    color: #fff!important;
    background-color: #005e85!important;
    border-radius: 21px;
    border-color: #005e85!important;
  }
  .pill-menu:hover, .pill-menu:active, .pill-menu:focus {
    color: #fff!important;
    background-color: #005e85!important;
  }
  .header-ico {
    position: absolute;
    top: 4px;
    width: 100px;
    z-index: 8888;
  }
}
@media only screen and (max-width: 375px) and (orientation:portrait) {
  html, body {
    moz-transform: scale(0.5);
    zoom: 50%;
  }
  .pb-50 {
    padding-bottom: 112px !important;
  }
  .dropdown-toggle.menu-styles.nav-link.pill-menu.dropdown.nav-item::after{
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: normal;
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }
  .dropdown-toggle.menu-styles.nav-link.dropdown.nav-item::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: normal;
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }
  .dropdown-item {
    display: flex;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #fff !important;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    justify-content: center;
  }
  .dropdown-menu.show {
    background-color: #005e85 !important;
    padding: 0 0 !important;
    height: auto !important;
    width: auto !important;
    align-items: center !important;
    border: none !important;
  }
  .dropdown-item {
    color: #fff !important;
    align-items: self-start !important;
  }
  .dropdown-item:hover, .dropdown-item:active, .dropdown-item:focus {
    color: #fff!important;
    background-color: transparent!important;
    box-shadow: 0px 0px 0px transparent!important;
    border-color: transparent!important;
  }
  .nav-link {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
  }

  .nav-item.active .nav-link { color: #2fa753!important;
    font-weight: 600;
  }
  .pill-menu {
    color: #fff!important;
    background-color: #005e85!important;
    border-radius: 21px;
    border-color: #005e85!important;
  }
  .pill-menu:hover, .pill-menu:active, .pill-menu:focus {
    color: #fff!important;
    background-color: #005e85!important;
  }
}
@media only screen and (max-width: 650px) and (orientation:landscape) {
  html, body {
    moz-transform: scale(0.5);
    zoom: 50%;
  }
  .pb-50 {
    padding-bottom: 115px !important;
  }
  .dropdown-toggle.menu-styles.nav-link.pill-menu.dropdown.nav-item::after{
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: normal;
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }
  .dropdown-toggle.menu-styles.nav-link.dropdown.nav-item::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: normal;
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }
  .dropdown-item {
    display: flex;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #fff !important;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    justify-content: center;
  }
  .dropdown-menu.show {
    background-color: #005e85 !important;
    padding: 0 0 !important;
    height: auto !important;
    width: auto !important;
    align-items: center !important;
    border: none !important;
  }
  .dropdown-item {
    color: #fff !important;
    align-items: self-start !important;
  }
  .dropdown-item:hover, .dropdown-item:active, .dropdown-item:focus {
    color: #fff!important;
    background-color: transparent!important;
    box-shadow: 0px 0px 0px transparent!important;
    border-color: transparent!important;
  }
  .nav-link {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
  }

  .nav-item.active .nav-link { color: #2fa753!important;
    font-weight: 600;
  }
  .pill-menu {
    color: #fff!important;
    background-color: #005e85!important;
    border-radius: 21px;
    border-color: #005e85!important;
  }
  .pill-menu:hover, .pill-menu:active, .pill-menu:focus {
    color: #fff!important;
    background-color: #005e85!important;
  }
}
#root {
  height: 100%;
}

.login {
  padding: 0 0;
  text-align: center;
}

.login form {
  margin: 0 auto;
  max-width: 480px;
}

.bg-dk-grey {
  background-color: #7f7f7f
}
.bg-orange {
  background-color: #ed7d31
}
.bg-green {
  background-color: #70ad47
}
.bg-silver {
  background-color: #d9d9d9
}
.bg-gold {
  background-color: #ffff00
}

.button-list .dropdown-menu {
  min-width: 5rem!important;
}

#root > div {
  height: 100%;
}

.img-container {
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  overflow:hidden;
}

.img-container img{
  flex-shrink:0;
  -webkit-flex-shrink: 0;
  max-width:70%;
  max-height:90%;
}

.accordion>.card {
    overflow: visible!important;
}

.ReactModal__Content.ReactModal__Content--after-open {
  overflow: visible!important;
}

.basic-tbl {
  border-collapse: collapse;
}

.basic-tbl td {
  border: 1px solid #000;
}

#cdiAppContainer {
  margin-bottom: 50px;
}

.grey-header-space {
  margin-top: 10px;
}
.container-left {
  margin-left: 2px;
  text-align: left !important;
}
.container-left-minus22 {
  margin-left: -22px;
  text-align: left !important;
}
.container-left-minus13 {
  margin-left: -13px;
  text-align: left !important;
}
.container-left-plus20 {
  margin-left: 20px;
  text-align: left !important;
}
.container-left-plus6 {
  margin-left: 6px;
  text-align: left !important;
}

.pt-50 {
  padding-top: 55px;
}

.pb-50 {
  padding-bottom: 65px;
}

.vertical-txt {
  writing-mode: vertical-rl;
}

.text-vertical-180 {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-align: left;
}

.title-vertical-180 {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-align: center;
  padding: 0 48px 0 0;
}

.custom-file-label {
  white-space: nowrap!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
}

.team-select-dropdown .dropdown-menu, .company-select-dropdown .dropdown-menu {
  max-height: 300px!important;
  overflow-y: auto!important;
}

.nav-pill-btn {
  padding: 5px 25px!important;
  border: 2px solid #005e85!important;
  background-color: transparent!important;
}

.nav-pill-btn-1 {
  padding: 3.5px 25px!important;
  border: 2px solid #005e85!important;
  background-color: transparent!important;
  width: 72.8px;
}

.cdi-blue-outline {
  border: 2px solid #005e85!important;
  outline: none!important;
}

.cdi-grey-outline {
  border: 2px solid #bbb!important;
  outline: none!important;
}

.white-outline {
  border: 2px solid #fff!important;
  outline: none!important;
}

.btn-group-toggle .btn {
  background-color: #fff!important;
  color: #005e85!important;
  border: 2px solid #005e85!important;
  font-weight: bold;
  outline: none!important;
}

.btn-group-toggle .btn.active {
  color: #fff!important;
  background-color: #005e85!important;
}

.extend-container {
  margin-left: -15px!important;
  margin-right: 0px!important;
}

.btn.btn-primary {
  background-color: #005e85;
  border-color: #005e85;
}

.scrollmenu::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.scrollmenu::-webkit-scrollbar {
	height: 6px;
	background-color: #F5F5F5;
}

.scrollmenu::-webkit-scrollbar-thumb {
	background-color: #28a745;
  border-radius: 10px;
}

.drop-scroll::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.drop-scroll::-webkit-scrollbar {
	height: 6px;
	background-color: #F5F5F5;
}

.drop-scroll::-webkit-scrollbar-thumb {
	background-color: #dadada;
  border-radius: 10px;
}


div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
  position: fixed;
  width: 100%;
  bottom: 58px;
}

div.scrollmenu span {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
  cursor: pointer ;
  color: #000;
  font-weight: 400;
}

div.scrollmenu span.active {
  background-color: #28a745;
  color: #fff;
  font-weight: 600;
  scroll-snap-align: center;
}

.overlay {
  left: 0;
  top: 100px;
  bottom: 58px;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  z-index: 99;
  overflow: hidden;
}

.page-loader {
  position: absolute;
  top: 47%;
  left: 47%;
  z-index: 999;
  background-color: #005e85;
  padding: 20px 50px;
  border-radius: 10px;
  transform: translate(-50%, -50%);
}

.page-loader p {
  letter-spacing: 2px;
}

.arrow-btn svg {
  color: #005e85!important;
}

.arrow-btn svg path{
  background-color: #fff!important;
}

.table-sub-heading {
  background-color: #005e85;
  padding: 10px 5px;
}

.table-sub-heading svg, .table-sub-heading h5 {
  color: #fff!important;
}

.table-sub-heading .btn-minimal {
  border-color: #fff!important;
  background-color: #005e85!important;
}

.table-sub-heading .btn-minimal:hover, .table-sub-heading .btn-minimal:focus, .table-sub-heading .btn-minimal:disabled, .table-sub-heading .btn-minimal:active {
  border-color: #fff!important;
  background-color: #005e85!important;
}

.h-100 {
  height: 100%!important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) and (orientation:landscape) and (orientation:portrait) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navbar {
    background-color: #005e85!important;
    height: 100px;
    width: 100%;
}
.navbar-nav {
  width: 80%;
}

.app-title-row {
  background-color: #dadada;
  padding: 8px 10px 7px 10px;
  position: absolute;
  top: 100px;
  height: 55px;
}

.nav-item {
  margin: auto;
  text-decoration: none;
}
.nav-link {
    color: #fff!important;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
}

.nav-item.active .nav-link {
    color: #2fa753!important;
    font-weight: 600;
}

.nav-pill {
  color: #005e85!important;
  background-color: #fff;
  padding: .5rem 3rem!important;
  border-radius: 21px;
  border-color: #fff!important;
}

/*.nav-pill:hover, .nav-pill:active, .nav-pill:focus {*/
/*  background-color: #fff!important;*/
/*  box-shadow: 0px 0px 0px #fff!important;*/
/*  border-color: #fff!important;*/
/*}*/

.pointer {
  cursor: pointer;
}

.no-select {
  user-select: none;
}

/* Team Identity Styling */
.team-identity-text {
  margin-top: 7px;
}

/* Footer Styling */

footer {
  color: #fff;
  font-weight: 500;
  background-color: #005e85;
  z-index: 9999;
  bottom: 0;
  flex-shrink: none;
  position: fixed;
  width: 100%;
  height: 58px;
}
.team-select {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  font-size: 15px;
}
.team-select-text {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  font-size: 15px;
  padding: 8px 8px !important;
}

footer .select-team {
  bottom: 40px;
}

footer .select-team .select-team-item {
  cursor: pointer;
  background-color: #4086A4;
}

footer .select-team .select-team-item:hover {
  background-color: #005e85;
}

input.form-control {
  border-radius: 0px;
}

.form-label {
  font-weight: 600;
}

.page-title {
  margin: 20px 0px 15px 0px;
}

.csv-reader-input {
  display: inline;
}

.create-user-from {
  margin-top: 30px;
  border: 1px solid rgba(0,0,0,0.2);
  padding: 15px 40px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.cdi-ddl {
  margin-left: 15px;
  width: 200px;
}

.goog-te-combo {
  display: block!important;
  margin-top: 15px!important;
  width: auto!important;
  height: calc(1.5em + .75rem + 2px)!important;
  padding: .375rem .75rem!important;
  font-size: 1rem!important;
  font-weight: 400!important;
  line-height: 1.5!important;
  color: #495057!important;
  background-color: #fff!important;
  background-clip: padding-box!important;
  border: 1px solid #ced4da!important;
  border-radius: .25rem!important;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out!important;
  z-index: 999999!important;
}

.p0 {
  padding: 0px;
}

.pl-20 {
  padding-left: 20px;
}

.pt-16 {
  padding-top: 16px;
}

.text-upper {
  text-transform: uppercase;
}

/* Company Screen Styling */


.cdi-txt-lbl {
  font-weight: 600;
  display: block;
  color: #005e85!important;
}

.cdi-blue-txt {
  margin-left: 20px;
  color: #005e85;
  font-weight: 600;
}

.meeting-view-txt {
  font-weight: 700;
  font-size: 16px;
}

.cdi-red-txt {
  margin-left: 20px;
  color: #c00000;
  font-weight: 600;
}

/* Structure Screen Styling */

.department-node-row {
  width: 100%;
}
.department-structure-container.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.department-structure-container.non-draggable {
  pointer-events: none;
}

.department-structure-container .rst__tree {
  height: 100%;
}

.department-structure-container .ReactVirtualized__Grid {
  overflow: visible!important;
}

.department-structure-container .ReactVirtualized__Grid__innerScrollContainer {
  margin-bottom: 120px;
}

.department-node-row td {
  padding: 0px 5px!important;
  vertical-align: middle!important;
}

.department-node-row td:first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis!important;
  max-width: 280px!important;
}

.department-node-row td .cdi-blue-txt {
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis!important;
}

.cdi-tree-view, .cdi-tree-view ul {
  list-style-type: none;
  padding-left: 2px;
}

.cdi-tree-view ul li {
  border-left: 2px solid #005e85;
  padding-left:60px;
}

.cdi-tree-view .tree-label {
  color: #005e85;
  font-weight: 600;
  margin: 10px 0px;
}

.cdi-tree-view ul .tree-label::before {
  content: "";
  display: block;
  height: 2px;
  background-color: #005e85;
  width: 50px;
  margin-bottom: -13px;
  margin-left: -61px;
}

.team-type-label {
  color: #8e8e8e;
  font-weight: 600;
}

.team-item {
  display:flex;
  width:100%;
}

.team-item select {
  margin-left: auto;
  border-radius: 0px;
}

.btn-minimal, .btn-minimal:hover, .btn-minimal:focus, .btn-minimal:active  {
  padding: 2px 2px 2px 6px!important;
  background-color: #fff!important;
  outline: 0px!important;
  box-shadow: none!important;
  height: 32px!important;
  width: 32px;
  margin-bottom: 2px;
  margin-left: 4px;
  border-radius: 100%;
  border: 1px solid #005e85;
}

.btn-minimal.more-padding {
  /*padding: 3px 4px 3px 8px!important;*/
}

.btn-minimal.arrow-padding {
  padding: 3px 6px 2px 9px!important;
}

.color-blue {
  color: #005e85!important;
}

.color-white {
  color: #fff!important;
}

.color-red {
  color: #c00000!important;
}

.table-container {
  display:block;
}

.rst__row {
  border: solid #bbb 1px;
}

.rst__rowLabel {
  flex: 1 1 auto;
}

.rst__moveHandle {
  border-radius: 0px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  background-color: #c3c3c3;
  border: 0px;
}

.rst__rowContents {
  border-radius: 0px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  background-color: #f5f5f5;
  border: 0px;
}

.rst__rowContents .cdi-blue-txt {
  font-size: 20px;
  margin-bottom: 5px;
}


.department-structure-container .rst__nodeContent {
  right: 0;
  height: 95px;
}

@media only screen and (max-width: 1100px) and (orientation:landscape) and (orientation:portrait) {
  .department-node-row td:first-child {
    max-width: 150px !important;
  }
  .team-select-text {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    font-size: 12px !important;
    padding: 8px 8px !important;
  }
}

@media only screen and (max-width: 1022px) and (orientation:landscape) and (orientation:portrait) {
  .cdi-blue-txt {
    margin-left: 0;
  }
  .cdi-red-txt {
    margin-left: 0;
  }
  .team-select-text {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    font-size: 9px !important;
    padding: 8px 8px !important;
  }
}

@media only screen and (max-width: 991px) and (orientation:landscape) and (orientation:portrait) {
  .team-select-text {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    font-size: 15px !important;
    padding: 8px 48px !important;
  }
}

@media only screen and (max-width: 1100px) and (orientation:landscape) {
  .team-select-text {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    font-size: 12px !important;
    padding: 8px 8px !important;
  }
}
@media only screen and (max-width: 1022px) and (orientation:landscape) {
  .team-select-text {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    font-size: 9px !important;
    padding: 8px 8px !important;
  }
}
@media only screen and (max-width: 991px) and (orientation:landscape) {
  .team-select-text {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    font-size: 15px !important;
    padding: 8px 48px !important;
  }
}

/* Users Screen Styling */

/*.users-tbl {*/
/*  width: 100%;*/
/*  border-collapse: collapse;*/
/*}*/

/*.users-tbl th, .users-tbl td {*/
/*  padding: 7px 10px;*/
/*}*/
/*
.users-tbl th:nth-last-child(-n+4), .users-tbl td:nth-last-child(-n+4) {
  background-color: #d9d9d9;
} */

/*.users-tbl th {*/
/*  text-align: center;*/
/*}*/

/*.users-tbl td {*/
/*  color: #005e85;*/
/*  font-weight: 600;*/
/*}*/

/*.users-tbl span.chk {*/
/*  width:20px;*/
/*  height: 20px;*/
/*  display:block;*/
/*  border-radius: 100%;*/
/*  border: 2px solid #aaaaaa;*/
/*}*/

.circle-red {
  background-color: #c00000;
}

.circle-blue {
  background-color: #005e86;
}

.circle-white {
  background-color: #fff;
}

.chk {
  width:20px;
  height: 20px;
  display:block;
  border-radius: 100%;
  border: 2px solid #aaaaaa;
}

.chk-red {
  background-color: #c00000;
}

.chk-blue {
  background-color: #005e86;
}

.chk-black {
  background-color: #000;
}

.chk-red-outline {
  border-color: #c00000;
  background-color: #fff;
}

.chk-lg {
  width: 23px!important;
  height: 23px!important;
}

.chk-white {
  background-color: #fff;
}

.true-north-table > tbody > tr > td:first-child {
  /*width: 20%;*/
  width: 10.2%;
  text-align: right;
  /*text-align: left;*/
}

.true-north-table > tbody > tr > td:last-child {
  /*width: 80%;*/
  width: 89.8%;
}

.bordered-fix td{
  padding: .55rem !important;
}
.bordered-minus {
  margin-top: -5px;
  margin-left: 10px;
  width: 98% !important;
}
.bordered-minus-1 {
  margin-top: -5px;
  margin-left: 0;
}
.bordered-minus-2 {
  margin-top: 5px;
  margin-left: 0;
}
.pl-plus-8 {
  margin-left: 8px !important;
}


/* Users Screen Styling */

/*.users-tbl {*/
/*  width: 100%;*/
/*  border-collapse: collapse;*/
/*}*/

/*.users-tbl th, .users-tbl td {*/
/*  padding: 7px 10px;*/
/*}*/
/*
.users-tbl th:nth-last-child(-n+4), .users-tbl td:nth-last-child(-n+4) {
  background-color: #d9d9d9;
} */

/*.goal-tbl th {*/
/*  text-align: center;*/
/*}*/

/*.goal-tbl td {*/
/*  color: #005e85;*/
/*  font-weight: 600;*/
/*}*/

/*.goal-tbl span.chk {*/
/*  width:20px;*/
/*  height: 20px;*/
/*  display:block;*/
/*  border-radius: 100%;*/
/*  border: 2px solid #aaaaaa;*/
/*}*/

/*.value-tbl th {*/
/*  text-align: center;*/
/*}*/

/*.value-tbl td {*/
/*  color: #005e85;*/
/*  font-weight: 600;*/
/*}*/

/*.value-tbl span.chk {*/
/*  width:20px;*/
/*  height: 20px;*/
/*  display:block;*/
/*  border-radius: 100%;*/
/*  border: 2px solid #aaaaaa;*/
/*}*/

/*.measurement-tbl th {*/
/*  text-align: center;*/
/*}*/

/*.measurement-tbl td {*/
/*  color: #005e85;*/
/*  font-weight: 600;*/
/*}*/

/*.measurement-tbl span.chk {*/
/*  width:20px;*/
/*  height: 20px;*/
/*  display:block;*/
/*  border-radius: 100%;*/
/*  border: 2px solid #aaaaaa;*/
/*}*/

/*.measurement-tbl tr td:nth-child(1) {*/
/*  width: 15%;*/
/*}*/

/*.measurement-tbl tr td:nth-child(2) {*/
/*  width: 60%;*/
/*}*/

/*.measurement-tbl tr td:nth-child(3) {*/
/*  width: 15%;*/
/*}*/

/*.measurement-tbl tr td:nth-child(4) {*/
/*  width: 10%;*/
/*}*/

/*.team-type-tbl th {*/
/*  text-align: center;*/
/*}*/

/*.team-type-tbl td {*/
/*  color: #005e85;*/
/*  font-weight: 600;*/
/*}*/

/*.team-type-tbl span.chk {*/
/*  width:20px;*/
/*  height: 20px;*/
/*  display:block;*/
/*  border-radius: 100%;*/
/*  border: 2px solid #aaaaaa;*/
/*}*/

/*.team-type-tbl tr td:nth-child(1) {*/
/*  width: 70%;*/
/*}*/

/*.team-type-tbl tr td:nth-child(2) {*/
/*  width: 15%;*/
/*}*/

/*.team-type-tbl tr td:nth-child(3) {*/
/*  width: 15%;*/
/*}*/

/*.goal-cat-tbl th {*/
/*  text-align: center;*/
/*}*/

/*.goal-cat-tbl td {*/
/*  color: #005e85;*/
/*  font-weight: 600;*/
/*}*/

/*.goal-cat-tbl span.chk {*/
/*  width:20px;*/
/*  height: 20px;*/
/*  display:block;*/
/*  border-radius: 100%;*/
/*  border: 2px solid #aaaaaa;*/
/*}*/

/*.goal-cat-tbl tr td:nth-child(1) {*/
/*  width: 80%;*/
/*}*/

/*.goal-cat-tbl tr td:nth-child(2) {*/
/*  width: 20%;*/
/*}*/

/*.units-tbl th {*/
/*  text-align: center;*/
/*}*/

/*.units-tbl td {*/
/*  color: #005e85;*/
/*  font-weight: 600;*/
/*}*/

/*.units-tbl span.chk {*/
/*  width:20px;*/
/*  height: 20px;*/
/*  display:block;*/
/*  border-radius: 100%;*/
/*  border: 2px solid #aaaaaa;*/
/*}*/

/*.units-tbl tr td:nth-child(1) {*/
/*  width: 80%;*/
/*}*/

/*.units-tbl tr td:nth-child(2) {*/
/*  width: 20%;*/
/*}*/

/*@media only screen and (max-width: 950px) and (orientation:landscape) and (orientation:portrait) {*/
/*  .header-ico {*/
/*    width: 80px!important;*/
/*  }*/
/*  table.users-tbl , .users-tbl thead, .users-tbl tbody, .users-tbl tr,  .users-tbl td {*/
/*    display: block;*/
/*  }*/

/*  .users-tbl thead tr {*/
/*		position: absolute;*/
/*		top: -9999px;*/
/*		left: -9999px;*/
/*  }*/

/*  .users-tbl tr {*/
/*    border: 1px solid #dee2e6;*/
/*  }*/

/*  .users-tbl td {*/
/*		border: none;*/
/*		border-bottom: 1px solid #dee2e6;*/
/*		position: relative;*/
/*    padding-left: 180px;*/
/*    min-height: 39px;*/
/*  }*/

/*  .users-tbl tr td:last-child {*/
/*    padding-left: 0px;*/
/*  }*/

/*  .users-tbl td.text-right {*/
/*    text-align: left !important;*/
/*  }*/

/*  .users-tbl td:before {*/
/*    position: absolute;*/
/*    top: 6px;*/
/*    left: 6px;*/
/*    width: 170px;*/
/*    padding-right: 10px;*/
/*    white-space: nowrap;*/
/*  }*/

/*  .users-tbl td:nth-of-type(1):before {*/
/*    content: "Last Name";*/
/*  }*/
/*  .users-tbl td:nth-of-type(2):before {*/
/*    content: "First Name(s)";*/
/*  }*/
/*  .users-tbl td:nth-of-type(3):before {*/
/*    content: "E-mail";*/
/*  }*/
/*  .users-tbl td:nth-of-type(4):before {*/
/*    content: "Mobile Number";*/
/*  }*/
/*  .users-tbl td:nth-of-type(5):before {*/
/*    content: "Nickname";*/
/*  }*/
/*  .users-tbl td:nth-of-type(6):before {*/
/*    content: "Password";*/
/*  }*/
/*  .users-tbl td:nth-of-type(7):before {*/
/*    content: "Role";*/
/*  }*/

/*  table.goal-tbl , .goal-tbl thead, .goal-tbl tbody, .goal-tbl tr,  .goal-tbl td {*/
/*    display: block;*/
/*  }*/

/*  .goal-tbl thead tr {*/
/*		position: absolute;*/
/*		top: -9999px;*/
/*		left: -9999px;*/
/*  }*/

/*  .goal-tbl tr {*/
/*    border: 1px solid #dee2e6;*/
/*  }*/

/*  .goal-tbl td {*/
/*		border: none;*/
/*		border-bottom: 1px solid #dee2e6;*/
/*		position: relative;*/
/*    padding-left: 180px;*/
/*    min-height: 39px;*/
/*  }*/

/*  .goal-tbl tr td:last-child {*/
/*    padding-left: 0px;*/
/*  }*/

/*  .goal-tbl td.text-right {*/
/*    text-align: left !important;*/
/*  }*/

/*  .goal-tbl td:before {*/
/*    position: absolute;*/
/*    top: 6px;*/
/*    left: 6px;*/
/*    width: 170px;*/
/*    padding-right: 10px;*/
/*    white-space: nowrap;*/
/*  }*/

/*  .goal-tbl td:nth-of-type(1):before {*/
/*    content: "Measurement";*/
/*  }*/
/*  .goal-tbl td:nth-of-type(2):before {*/
/*    content: "Units";*/
/*  }*/
/*  .goal-tbl td:nth-of-type(3):before {*/
/*    content: "Frequency";*/
/*  }*/
/*  .goal-tbl td:nth-of-type(4):before {*/
/*    content: "From";*/
/*  }*/
/*  .goal-tbl td:nth-of-type(5):before {*/
/*    content: "To";*/
/*  }*/
/*  .goal-tbl td:nth-of-type(6):before {*/
/*    content: "By";*/
/*  }*/
/*  .goal-tbl td:nth-of-type(7):before {*/
/*    content: "";*/
/*  }*/

/*  table.value-tbl , .value-tbl thead, .value-tbl tbody, .value-tbl tr,  .value-tbl td {*/
/*    display: block;*/
/*  }*/

/*  .value-tbl thead tr {*/
/*		position: absolute;*/
/*		top: -9999px;*/
/*		left: -9999px;*/
/*  }*/

/*  .value-tbl tr {*/
/*    border: 1px solid #dee2e6;*/
/*  }*/

/*  .value-tbl td {*/
/*		border: none;*/
/*		border-bottom: 1px solid #dee2e6;*/
/*		position: relative;*/
/*    padding-left: 180px;*/
/*    min-height: 39px;*/
/*  }*/

/*  .value-tbl tr td:last-child {*/
/*    padding-left: 0px;*/
/*  }*/

/*  .value-tbl td.text-right {*/
/*    text-align: left !important;*/
/*  }*/

/*  .value-tbl td:before {*/
/*    position: absolute;*/
/*    top: 6px;*/
/*    left: 6px;*/
/*    width: 170px;*/
/*    padding-right: 10px;*/
/*    white-space: nowrap;*/
/*  }*/

/*  .value-tbl td:nth-of-type(1):before {*/
/*    content: "Value";*/
/*  }*/
/*  .value-tbl td:nth-of-type(2):before {*/
/*    content: "Definition";*/
/*  }*/

/*  table.measurement-tbl , .measurement-tbl thead, .measurement-tbl tbody, .measurement-tbl tr,  .measurement-tbl td {*/
/*    display: block;*/
/*  }*/

/*  .measurement-tbl thead tr {*/
/*		position: absolute;*/
/*		top: -9999px;*/
/*		left: -9999px;*/
/*  }*/

/*  .measurement-tbl tr {*/
/*    border: 1px solid #dee2e6;*/
/*  }*/

/*  .measurement-tbl td {*/
/*		border: none;*/
/*		border-bottom: 1px solid #dee2e6;*/
/*		position: relative;*/
/*    padding-left: 180px;*/
/*    min-height: 39px;*/
/*    width: 100%!important;*/
/*  }*/

/*  .measurement-tbl tr td:last-child {*/
/*    padding-left: 0px;*/
/*  }*/

/*  .measurement-tbl td.text-right {*/
/*    text-align: left !important;*/
/*  }*/

/*  .measurement-tbl td:before {*/
/*    position: absolute;*/
/*    top: 6px;*/
/*    left: 6px;*/
/*    width: 170px;*/
/*    padding-right: 10px;*/
/*    white-space: nowrap;*/
/*  }*/

/*  .measurement-tbl td:nth-of-type(1):before {*/
/*    content: "Goal Category";*/
/*  }*/
/*  .measurement-tbl td:nth-of-type(2):before {*/
/*    content: "Measurement";*/
/*  }*/

/*  .measurement-tbl td:nth-of-type(3):before {*/
/*    content: "Unit";*/
/*  }*/
/*  .measurement-tbl td:nth-of-type(4):before {*/
/*    content: "";*/
/*  }*/

/*  table.team-type-tbl , .team-type-tbl thead, .team-type-tbl tbody, .team-type-tbl tr,  .team-type-tbl td {*/
/*    display: block;*/
/*  }*/

/*  .team-type-tbl thead tr {*/
/*		position: absolute;*/
/*		top: -9999px;*/
/*		left: -9999px;*/
/*  }*/

/*  .team-type-tbl tr {*/
/*    border: 1px solid #dee2e6;*/
/*  }*/

/*  .team-type-tbl td {*/
/*		border: none;*/
/*		border-bottom: 1px solid #dee2e6;*/
/*		position: relative;*/
/*    padding-left: 180px;*/
/*    min-height: 39px;*/
/*    width: 100%!important;*/
/*  }*/

/*  .team-type-tbl tr td:last-child {*/
/*    padding-left: 0px;*/
/*  }*/

/*  .team-type-tbl td.text-right {*/
/*    text-align: left !important;*/
/*  }*/

/*  .team-type-tbl td:before {*/
/*    position: absolute;*/
/*    top: 6px;*/
/*    left: 6px;*/
/*    width: 170px;*/
/*    padding-right: 10px;*/
/*    white-space: nowrap;*/
/*  }*/

/*  .team-type-tbl td:nth-of-type(1):before {*/
/*    content: "Team Type Description";*/
/*  }*/

/*  .team-type-tbl td:nth-of-type(2):before {*/
/*    content: "Type Level";*/
/*  }*/

/*  .team-type-tbl td:nth-of-type(3):before {*/
/*    content: "";*/
/*  }*/

/*  table.goal-cat-tbl , .goal-cat-tbl thead, .goal-cat-tbl tbody, .goal-cat-tbl tr,  .goal-cat-tbl td {*/
/*    display: block;*/
/*  }*/

/*  .goal-cat-tbl thead tr {*/
/*		position: absolute;*/
/*		top: -9999px;*/
/*		left: -9999px;*/
/*  }*/

/*  .goal-cat-tbl tr {*/
/*    border: 1px solid #dee2e6;*/
/*  }*/

/*  .goal-cat-tbl td {*/
/*		border: none;*/
/*		border-bottom: 1px solid #dee2e6;*/
/*		position: relative;*/
/*    padding-left: 180px;*/
/*    min-height: 39px;*/
/*    width: 100%!important;*/
/*  }*/

/*  .goal-cat-tbl tr td:last-child {*/
/*    padding-left: 0px;*/
/*  }*/

/*  .goal-cat-tbl td.text-right {*/
/*    text-align: left !important;*/
/*  }*/

/*  .goal-cat-tbl td:before {*/
/*    position: absolute;*/
/*    top: 6px;*/
/*    left: 6px;*/
/*    width: 170px;*/
/*    padding-right: 10px;*/
/*    white-space: nowrap;*/
/*  }*/

/*  .goal-cat-tbl td:nth-of-type(1):before {*/
/*    content: "Goal Category Description";*/
/*  }*/

/*  table.units-tbl , .units-tbl thead, .units-tbl tbody, .units-tbl tr,  .units-tbl td {*/
/*    display: block;*/
/*  }*/

/*  .units-tbl thead tr {*/
/*		position: absolute;*/
/*		top: -9999px;*/
/*		left: -9999px;*/
/*  }*/

/*  .units-tbl tr {*/
/*    border: 1px solid #dee2e6;*/
/*  }*/

/*  .units-tbl td {*/
/*		border: none;*/
/*		border-bottom: 1px solid #dee2e6;*/
/*		position: relative;*/
/*    padding-left: 180px;*/
/*    min-height: 39px;*/
/*    width: 100%!important;*/
/*  }*/

/*  .units-tbl tr td:last-child {*/
/*    padding-left: 0px;*/
/*  }*/

/*  .units-tbl td.text-right {*/
/*    text-align: left !important;*/
/*  }*/

/*  .units-tbl td:before {*/
/*    position: absolute;*/
/*    top: 6px;*/
/*    left: 6px;*/
/*    width: 170px;*/
/*    padding-right: 10px;*/
/*    white-space: nowrap;*/
/*  }*/

/*  .units-tbl td:nth-of-type(1):before {*/
/*    content: "Units Description";*/
/*  }*/

/*  .team-identity-container .mob-no-padding {*/
/*    padding-left: 0px!important;*/
/*  }*/

/*  .true-north-table > tbody > tr > td {*/
/*    display: block;*/
/*    width: 100%!important;*/
/*    text-align: left!important;*/
/*    padding: 0px!important;*/
/*  }*/
/*}*/

/* Chrome, Safari, Edge, Opera */
.controls-input::-webkit-outer-spin-button,
.controls-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.controls-input[type=number] {
  -moz-appearance: textfield;
}

/*@media only screen and (max-width: 620px) and (orientation:landscape) and (orientation:portrait) {*/
/*  .users-tbl td {*/
/*    padding-left: 140px;*/
/*    word-break: break-word;*/
/*  }*/

/*  .users-tbl td:before {*/
/*    width: 130px;*/
/*  }*/
/*}*/

/* Chart Styling */

.cdi-xaxis-labels text {
  border: 1px solid black !important;
}


/* True North Styling */

.true-north-table > tbody > tr > td {
  /*padding: 30px 10px 0 10px;*/
  padding: 0 10px 0 2px;
}

.btn-circle {
  border-radius: 100%!important;
}

.measurements-table {
  margin-top: 60px;
}

.measurements-table table tr {
  background-color: rgba(255, 255, 255, 0)!important;
}

.measurements-table table td {
  border: 0px !important;
  padding-top: 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*@media all and (max-width: 1100px) and (orientation:landscape) and (orientation:portrait) {*/
/*  .navbar-nav {*/
/*    width: 100%;*/
/*  }*/
/*}*/

/*@media all and (min-width: 480px) and (orientation:landscape) and (orientation:portrait) {*/
/*  .login {*/
/*    padding: 0 0;*/
/*    text-align: center;*/
/*  }*/

/*  .login form {*/
/*    margin: 0 auto;*/
/*    max-width: 480px;*/
/*  }*/
/*}*/

/* Team Partners Styling */

.create-partner-form {
  border: 1px #bbb solid;
  padding: 1em;
}

/* Team Attendance Styling */

.attendance-date-border {
  border-color: #005e85 !important;
  border-width: 2px !important;
}

.attendance-cell {
  min-width: 2em;
  min-height: 2em;
  cursor: pointer;
  user-select: none;
}

.review-cell {
  min-width: 2em;
  min-height: 1.9em;
  cursor: pointer;
  padding-top: 2px;
  user-select: none;
  border-radius: 50%;
  border: 1px solid #000!important;
}

/* Team Goals Styling */


/*@media only screen and (max-width: 650px) and (orientation:landscape) and (orientation:portrait) {*/
/*  .team-goals-category {*/
/*    text-align: center;*/
/*  }*/

/*  .team-goals-container {*/
/*    flex-direction: column;*/
/*  }*/
/*}*/
 .team-goals-container {
  position: relative;
  height: 100vh;
}

.chart-navigation{
  position: absolute;
  width: 100%;
} 

.input-result {
  position: absolute;
  width: 200px;
  min-height: 250px;
  margin-left: -100px;
  margin-top: -100px;
  left: 50%;
  top: 50%;
  z-index: 100;
  padding-bottom: 10px;
}

.datepicker-100 .react-datepicker-wrapper {
  width: 100%!important;
}

/* Skills Matrix */

.skill-border {
  border: 2pt solid;
}

.skill-border-lg {
  border: 3pt solid;
}

.skill-red-border {
  border-color: #dc3545!important;
}

.partner-grey-border {
  border-color: #bbb!important;
}

/* Customers Suppliers */
.customer-supplier-label {
  color: #514140;
  font-weight: 500;
}

.customer-supplier-tbl {
  width: 100%;
}

.bottom-border {
  border-bottom: 2px solid #ececec;
}

.customer-supplier-tbl > tbody > tr > td {
  padding: 20px 10px;
  vertical-align: top;
}

.customer-supplier-tbl > tbody > tr > td:first-child {
  border-right: 2px solid #ececec;
}

.customer-supplier-tbl th {
  /*color: #514140;*/
  color: #005e86;
  font-weight: 500;
}

.customer-supplier-tbl td {
  /*color: #005e86;*/
  color: #514140;
  font-weight: 400;
}

.customer-supplier-tbl table {
  width: 100%;
}

.chk svg {
  font-size: 10px!important;
  margin-left: 4px;
  margin-bottom: 6px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.member-dashboard-table {
  border-collapse: separate;
  /*border-spacing: 0px 10px;*/
}

.member-dashboard-table thead tr th {
  text-align: center;
  border: 0px!important;
}

.member-dashboard-table thead tr:first-child th {
  font-weight: 600;
  font-size: 14px;
}

.member-dashboard-table thead tr:nth-child(2) th {
  font-weight: 400;
  font-size: 10px;
}

.member-dashboard-table tbody tr td {
  border: 0px;
  padding: 0px .75rem;
}

.member-dashboard-table tbody tr td:first-child {
  text-align: left;
  font-weight: 400;
  font-size: 15px;
  vertical-align: middle;
}

.member-dashboard-table tbody tr td div {
  color: #fff;
  font-weight: 500;
  width: 50%;
  height: 100%;
  margin: 0px auto;
  padding: 7px 0px;
  text-align: center;
  cursor: pointer;
}

.member-dashboard-table tbody tr td .toggle-blue {
  background-color: #005e86;
}

.member-dashboard-table tbody tr td .toggle-red {
  background-color: #c00000;
}

.team-dashboard-tbl {
  border-collapse: separate;
  border-spacing: 0px 4px;
  margin: 0px auto;
}

.team-dashboard-tbl th {
  vertical-align: middle!important;
  border-top: 0px!important;
  border-bottom: 0px!important;
}


.team-dashboard-tbl td {
  border-top: 0px;
}

.team-dashboard-header-cell {
  width: 100%;
  height: 100%;
  margin: 0px auto;
  padding: 7px 0px;
  text-align: center;
  border: 2px solid #005e85;
}

.team-stats-widget {
  border-collapse: collapse;
}

.team-stats-widget thead tr:first-child th {
  font-size: 16px;
  font-weight: bold;
}

.team-stats-widget thead tr:last-child th {
  font-size: 12px;
}

.team-stats-widget th, .team-stats-widget td {
  border: 0px!important;
  text-align: center;
}

.team-stats-widget tbody tr td {
  color: #fff;
  background-color: #005e85;
  font-size: 14px;
}

.team-stats-widget tbody tr td:first-child {
  font-weight: 600;
}
/* Borders */

.border-thick {
  border: solid 2px;
}

/* Colors */

.border-color-blue {
  border-color: #005e85 !important;
}

.border-color-red {
  border-color: #c00000 !important;
}

.border-color-light-blue {
  border-color: #4086A4 !important;
}

.border-color-light-grey {
  border-color: #E8E8E8;
}

.border-color-grey {
  border-color: #bbb;
}

.bg-blue {
  background-color: #005e85;
}

.bg-red, .bg-red:hover {
  background-color: #c00000!important;
}

.bg-green {
  background-color: #28a745;
}

.bg-light-blue {
  background-color: #4086A4;
}

.bg-grey {
  background-color: #bbb;
}

.bg-light-grey {
  background-color: #E8E8E8;
}

.text-grey {
  color: #bbb !important;
}

.blue-text {
  color: #005e85;
}

/* Utils */

.button-unstyled {
  border: 0 !important;
  outline: 0 !important;
  background-color: transparent !important;
}

.no-outline {
  outline: 0 !important;
}

.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.chart {
  max-height: 450px;
  max-height: 450px;
  justify-content: center;
  /* align-items: center; */
  display: flex;
}

.goal-title-container {
  background-color: #005e85;
  color: #fff!important;
}

.goal-title-container.row {
  margin-right: -15px!important;
}

.controls-container.row {
  margin-right: -15px!important;
}

.coaching-review-title-container {
  background-color: #005e85;
  color: #fff!important;
  /*margin-top: 19px;*/
}

.project-parent-row {
  color: #fff!important;
}

.project-order-buttons {
  margin-top: -9px;
  margin-bottom: -9px;
  padding-top: 9px;
  background-color: #fff;
}

.controls-container {
  background-color: #fff!important;
}

.controls-container label, .controls-container h6 {
  color: #005e85;
}
.VictoryContainer {
  margin:auto!important;
}

.cdi-comment-box {
  background-color: #fff;
  border-radius: 20px;
  padding: 10px 20px;
}

.cdi-comment-box:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 24px solid #fff;
  border-right: 12px solid transparent;
  border-top: 12px solid #fff;
  border-bottom: 20px solid transparent;
}

/* Company */
/*@media only screen and (max-width: 950px) and (orientation:landscape) and (orientation:portrait) {*/
/*  #cdiAppContainer {*/
/*    min-height: 100vh;*/
/*    height: auto;*/
/*  }*/

/*  .row {*/
/*    flex-direction: row;*/
/*  }*/
/*  .column {*/
/*    flex-direction: column !important;*/
/*  }*/

/*  .mt-20:not(:first-of-type) {*/
/*    !* margin-top: 0; *!*/
/*    margin-bottom: 0;*/
/*  }*/

/*  .col, .col-md-4 {*/
/*    margin-top: 15px;*/
/*  }*/
/*}*/


.logout {
  cursor: pointer;
}

.footer-tbl td:last-child {
  text-align: right;
}

.team-select-dropdown {
  float: right;
}

.company-select-dropdown {
  float: left;
}
/* Mobile Responsiveness */

/*@media only screen and (max-width: 600px) and (orientation:landscape) and (orientation:portrait) {*/
/*  footer {*/
/*    height: 80px;*/
/*  }*/
/*  .team-select-dropdown, .company-select-dropdown {*/
/*    float: none;*/
/*    width: 100%;*/
/*  }*/
/*  .team-select {*/
/*    width: 100%;*/
/*  }*/
/*  .footer-tbl td {*/
/*    display: block;*/
/*    width: 100%;*/
/*    text-align: center;*/
/*  }*/

/*  .footer-tbl .dropdown-menu.show {*/
/*    width: 100%;*/
/*  }*/

/*  #cdiAppContainer {*/
/*    margin-bottom: 80px;*/
/*  }*/

/*  .btn-nrml {*/
/*    width: 50%;*/
/*  }*/

/*  .active-edit .btn-nrml {*/
/*    width: 45%;*/
/*  }*/

/*  .active-edit .btn-nrml:nth-child(1) {*/
/*    float: left;*/
/*  }*/

/*  .active-edit .btn-nrml:nth-child(2) {*/
/*    float: right;*/
/*  }*/
/*}*/
.header-ico {
  width: 100px;
  z-index: 8888;
}

input[type="number"] {
  text-align: center;
}
.pulse-btn{
  padding: 2px 2px 2px 6px!important;
  background-color: #fff!important;
  outline: 0px!important;
  box-shadow: none!important;
  border-radius: 100%;
  border: 1px solid #005e85;
  width: 32px;
  height: 32px;
}

.bg-cdi-blue, .bg-cdi-blue:hover, .bg-cdi-blue:active, .bg-cdi-blue:focus {
  background-color: #005e85!important;
}



.pulse-btn.active:before,.pulse-btn.active:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #005e85;
  bottom: 3px;
  width: 42px;
  height: 42px;
  margin-left: -5px;
  top: -1px;
  animation: animate 1.5s linear infinite;
  opacity: 0;
  justify-content: center;
  align-items: center;
}

@keyframes animate {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: scale(1.2);
      opacity: 0;
    }
}


/** Meeting Attendance **/

.font-color-negative {
  color: #dc3545;
}

.bg-color-negative {
  background-color: #dc3545;
}

.max-cell-width {
  max-width: 2rem;
}

.include-users-content {
  max-height: 400px;
  overflow-y: auto;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 100;
}


/*
React Big Calendar Styling -- Import not working
*/

@charset "UTF-8";
.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0; }

button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled].rbc-btn {
  cursor: not-allowed; }

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0; }

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch; }

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit; }

.rbc-abs-full, .rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.rbc-ellipsis, .rbc-event-label, .rbc-row-segment .rbc-event-content, .rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.rbc-rtl {
  direction: rtl; }

.rbc-off-range {
  color: #999999; }

.rbc-off-range-bg {

  background-color: rgba(0, 93, 133, 0.4); }

.rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #DDD;
  padding: 5px 10px;
  background-color: #005e85;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-left: 1px solid #fff;
 }
  .rbc-header + .rbc-header {
    border-left: 1px solid #fff;
    padding: 5px 10px;
    background-color: #005e85;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
   }
  .rbc-rtl .rbc-header + .rbc-header {
    border-left-width: 0;
    border-right: 1px solid #fff; }
  .rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
    color: inherit;
    text-decoration: none; }

.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4; }

.rbc-row-content-scrollable {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .rbc-row-content-scrollable .rbc-row-content-scroll-container {
    height: 100%;
    overflow-y: scroll;
    /* Hide scrollbar for Chrome, Safari and Opera */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */ }
    .rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
      display: none; }

.rbc-today {
  background-color: #eaf6ff; }

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px; }
  .rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 0 10px;
    font-size: 20px;
    font-weight: bold;
    text-align: center; }
  .rbc-toolbar button {
    color: #373a3c;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 1px solid #ccc;
    padding: .375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap; }
    .rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
      background-image: none;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      background-color: #e6e6e6;
      border-color: #adadad; }
      /*.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {*/
      /*  color: #373a3c;*/
      /*  background-color: #d4d4d4;*/
      /*  border-color: #8c8c8c; }*/
    .rbc-toolbar button:focus {
      color: #373a3c;
      background-color: #e6e6e6;
      border-color: #adadad; }
    .rbc-toolbar button:hover {
      color: #373a3c;
      background-color: #e6e6e6;
      border-color: #adadad; }

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap; }
  .rbc-btn-group button {
    color: #fff;
    background-color: #005e85;
  }
  .rbc-btn-group button:hover {
    background-color: #fff;
    color: #005e85;
  }
  .rbc-btn-group > button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .rbc-btn-group > button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .rbc-btn-group > button:not(:first-child):not(:last-child) {
    border-radius: 0; }
  .rbc-btn-group button + button {
    margin-left: -1px; }
  .rbc-rtl .rbc-btn-group button + button {
    margin-left: 0;
    margin-right: -1px; }
  .rbc-btn-group + .rbc-btn-group,
  .rbc-btn-group + button {
    margin-left: 10px; }

.rbc-event, .rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left; }
  .rbc-slot-selecting .rbc-event, .rbc-slot-selecting .rbc-day-slot .rbc-background-event, .rbc-day-slot .rbc-slot-selecting .rbc-background-event {
    cursor: inherit;
    pointer-events: none; }
  .rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
    background-color: #265985; }
  .rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
    outline: 5px auto #3b99fc; }

.rbc-event-label {
  font-size: 80%; }

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5); }

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.rbc-row {
  display: flex;
  flex-direction: row; }

.rbc-row-segment {
  padding: 0 1px 1px 1px; }

.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1); }

.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal; }

.rbc-month-view {
  position: relative;
  border: 1px solid #DDD;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%; }

.rbc-month-header {
  display: flex;
  flex-direction: row; }

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%; }
  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid #DDD; }

.rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  padding-right: 5px;
  text-align: right; }
  .rbc-date-cell.rbc-now {
    font-weight: bold; }
  .rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
    color: inherit;
    text-decoration: none; }

.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: hidden; }

.rbc-day-bg {
  flex: 1 0 0%; }
  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #DDD; }
  .rbc-rtl .rbc-day-bg + .rbc-day-bg {
    border-left-width: 0;
    border-right: 1px solid #DDD; }

.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px; }
  .rbc-overlay > * + * {
    margin-top: 1px; }

.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px; }

.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto; }
  .rbc-agenda-view table.rbc-agenda-table {
    width: 100%;
    border: 1px solid #DDD;
    border-spacing: 0;
    border-collapse: collapse; }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
      padding: 5px 10px;
      vertical-align: top; }
    .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
      padding-left: 15px;
      padding-right: 15px;
      text-transform: lowercase; }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
      border-left: 1px solid #DDD; }
    .rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
      border-left-width: 0;
      border-right: 1px solid #DDD; }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
      border-top: 1px solid #DDD; }
    .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
      padding: 3px 5px;
      text-align: left;
      border-bottom: 1px solid #DDD; }
      .rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
        text-align: right; }

.rbc-agenda-time-cell {
  text-transform: lowercase; }
  .rbc-agenda-time-cell .rbc-continues-after:after {
    content: ' »'; }
  .rbc-agenda-time-cell .rbc-continues-prior:before {
    content: '« '; }

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap; }

.rbc-agenda-event-cell {
  width: 100%; }

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%; }
  .rbc-time-column .rbc-timeslot-group {
    flex: 1; }

.rbc-timeslot-group {
  border-bottom: 1px solid #DDD;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap; }

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none; }

.rbc-label {
  padding: 0 5px; }

.rbc-day-slot {
  position: relative; }
  .rbc-day-slot .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    margin-right: 10px;
    top: 0; }
    .rbc-day-slot .rbc-events-container.rbc-rtl {
      left: 10px;
      right: 0; }
  .rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
    border: 1px solid #265985;
    display: flex;
    max-height: 100%;
    min-height: 20px;
    flex-flow: column wrap;
    align-items: flex-start;
    overflow: hidden;
    position: absolute; }
  .rbc-day-slot .rbc-background-event {
    opacity: 0.75; }
  .rbc-day-slot .rbc-event-label {
    flex: none;
    padding-right: 5px;
    width: auto; }
  .rbc-day-slot .rbc-event-content {
    width: 100%;
    flex: 1 1 0;
    word-wrap: break-word;
    line-height: 1;
    height: 100%;
    min-height: 1em; }
  .rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #f7f7f7; }

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid #DDD;
  z-index: 10;
  margin-right: -1px; }

.rbc-time-view-resources .rbc-time-header {
  overflow: hidden; }

.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0 0;
  flex-basis: 0px; }

.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none; }

.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px; }

.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex: 1 1 0;
  flex-basis: 0; }

.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px; }

.rbc-time-slot {
  flex: 1 0 0; }
  .rbc-time-slot.rbc-now {
    font-weight: bold; }

.rbc-day-header {
  text-align: center; }

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px; }

.rbc-slot-selecting {
  cursor: move; }

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border: 1px solid #DDD;
  min-height: 0; }
  .rbc-time-view .rbc-time-gutter {
    white-space: nowrap; }
  .rbc-time-view .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    position: relative; }
  .rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
    border-left: 1px solid #DDD; }
  .rbc-time-view .rbc-allday-events {
    position: relative;
    z-index: 4; }
  .rbc-time-view .rbc-row {
    box-sizing: border-box;
    min-height: 20px; }

.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row; }
  .rbc-time-header.rbc-overflowing {
    border-right: 1px solid #DDD; }
  .rbc-rtl .rbc-time-header.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid #DDD; }
  .rbc-time-header > .rbc-row:first-child {
    border-bottom: 1px solid #DDD; }
  .rbc-time-header > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #DDD; }

.rbc-time-header-cell-single-day {
  display: none; }

.rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid #DDD; }
  .rbc-rtl .rbc-time-header-content {
    border-left-width: 0;
    border-right: 1px solid #DDD; }
  .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #DDD;
    flex-shrink: 0; }

.rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #DDD;
  overflow-y: auto;
  position: relative; }
  .rbc-time-content > .rbc-time-gutter {
    flex: none; }
  .rbc-time-content > * + * > * {
    border-left: 1px solid #DDD; }
  .rbc-rtl .rbc-time-content > * + * > * {
    border-left-width: 0;
    border-right: 1px solid #DDD; }
  .rbc-time-content > .rbc-day-slot {
    width: 100%;
    user-select: none;
    -webkit-user-select: none; }

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none; }

.status {
  padding-bottom: 14px!important;
  width: 41.61px!important;
  height: 28px!important;
  border-radius: 50rem!important;
}

.indicator {
  width: 41.61px!important;
  height: 28px!important;
  border-radius: 50rem!important;
}

.table-heading-background th {
  background-color: #005e85;
  color: #fff;
}

.table-heading-text th {
  font-size: 1rem !important;
  font-weight: 800 !important;
  line-height: 1.5 !important;
}

.label-background {
  background-color: #005e85;
  color: #fff;
}

.user-text td {
  color: #000 !important;
}

.user-txt p {
  color: #000 !important;
}

/*@media all and (min-width: 480px) and (orientation:landscape) and (orientation:portrait) {*/
/*  .password {*/
/*    padding: 0 0;*/
/*    text-align: center;*/
/*  }*/

/*  .password form {*/
/*    margin: 0 auto;*/
/*    max-width: 480px;*/
/*  }*/
/*}*/

.td-legend {
  display: flex;
  justify-content: center;
  align-items: center;
}

.standard-tbl {
  width: 100%;
  border-collapse: collapse;
}

.standard-tbl th, .standard-tbl td {
  padding: 7px 10px;
}

.standard-tbl th {
  text-align: center;
}

.standard-tbl td {
  color: #005e85;
  font-weight: 600;
}

.standard-tbl span.chk {
  width:20px;
  height: 20px;
  display:block;
  border-radius: 100%;
  border: 2px solid #aaaaaa;
}

.standard-units-tbl tr td:nth-child(1) {
  width: 80%;
}
.standard-units-tbl tr td:nth-child(2) {
  width: 20%;
}

.standard-goal-cat-tbl tr td:nth-child(1) {
  width: 80%;
}
.standard-goal-cat-tbl tr td:nth-child(2) {
  width: 20%;
}

.standard-measurement-tbl tr td:nth-child(1) {
  width: 15%;
}
.standard-measurement-tbl tr td:nth-child(2) {
  width: 60%;
}
.standard-measurement-tbl tr td:nth-child(3) {
  width: 15%;
}
.standard-measurement-tbl tr td:nth-child(4) {
  width: 10%;
}

.standard-team-type-tbl tr td:nth-child(1) {
  width: 70%;
}

.standard-team-type-tbl tr td:nth-child(2) {
  width: 15%;
}

.standard-team-type-tbl tr td:nth-child(3) {
  width: 15%;
}

.team-success-tile {
  user-select: none;
  padding: 10px 20px 10px;
  border-radius: 10px;
  width: 220px;
  min-width: 180px;
  max-width: 180px;
  height: 200px;
  max-height: 200px;
  border: 2px solid rgb(0, 94, 133);
  color: rgb(255, 255, 255);
  background: rgb(218, 218, 218);
}

/*@media only screen and (max-width: 950px) and (orientation:landscape) and (orientation:portrait) {*/
/*  .header-ico {*/
/*    width: 80px!important;*/
/*    position: absolute;*/
/*    top: 10px;*/
/*    z-index: 8888;*/
/*  }*/

/*  table.standard-tbl, .standard-tbl thead, .standard-tbl tbody, .standard-tbl tr, .standard-tbl td {*/
/*    display: block;*/
/*  }*/

/*  .standard-tbl thead tr {*/
/*    position: absolute;*/
/*    top: -9999px;*/
/*    left: -9999px;*/
/*  }*/

/*  .standard-tbl tr {*/
/*    border: 1px solid #dee2e6;*/
/*  }*/

/*  .standard-tbl td {*/
/*    border: none;*/
/*    border-bottom: 1px solid #dee2e6;*/
/*    position: relative;*/
/*    padding-left: 240px;*/
/*    min-height: 39px;*/
/*    width: 100%!important;*/
/*  }*/

/*  .standard-tbl tr td:last-child {*/
/*    padding-left: 0px;*/
/*  }*/

/*  .standard-tbl td.text-right {*/
/*    text-align: left !important;*/
/*  }*/

/*  .standard-tbl td:before {*/
/*    position: absolute;*/
/*    top: 6px;*/
/*    left: 6px;*/
/*    width: 170px;*/
/*    padding-right: 10px;*/
/*    white-space: nowrap;*/
/*    text-align: left;*/
/*  }*/

/*  .standard-actions-tbl td:nth-of-type(1):before {*/
/*    content: "Date";*/
/*  }*/
/*  .standard-actions-tbl td:nth-of-type(2):before {*/
/*    content: "Issue / Problem";*/
/*  }*/
/*  .standard-actions-tbl td:nth-of-type(3):before {*/
/*    content: "Our Action";*/
/*  }*/
/*  .standard-actions-tbl td:nth-of-type(4):before {*/
/*    content: "Who";*/
/*  }*/
/*  .standard-actions-tbl td:nth-of-type(5):before {*/
/*    content: "When";*/
/*  }*/
/*  .standard-actions-tbl td:nth-of-type(6):before {*/
/*    content: "Status";*/
/*  }*/
/*  .standard-actions-tbl td:nth-of-type(7):before {*/
/*    content: "Escalated";*/
/*  }*/
/*  .standard-actions-tbl td:nth-of-type(8):before {*/
/*    content: "";*/
/*  }*/
/*  .standard-escalations-tbl td:nth-of-type(1):before {*/
/*    content: "Date";*/
/*  }*/
/*  .standard-escalations-tbl td:nth-of-type(2):before {*/
/*    content: "Issue / Problem";*/
/*  }*/
/*  .standard-escalations-tbl td:nth-of-type(3):before {*/
/*    content: "Feedback";*/
/*  }*/
/*  .standard-escalations-tbl td:nth-of-type(4):before {*/
/*    content: "Shared";*/
/*  }*/
/*  .standard-escalations-tbl td:nth-of-type(5):before {*/
/*    content: "";*/
/*  }*/
/*  .standard-escalations-tbl td:nth-of-type(6):before {*/
/*    content: "";*/
/*  }*/
/*  .standard-coaches-feedback-tbl td:nth-of-type(1):before {*/
/*    content: "Date";*/
/*  }*/
/*  .standard-coaches-feedback-tbl td:nth-of-type(2):before {*/
/*    content: "Feedback";*/
/*  }*/
/*  .standard-coaches-feedback-tbl td:nth-of-type(3):before {*/
/*    content: "Shared";*/
/*  }*/
/*  .standard-coaches-feedback-tbl td:nth-of-type(4):before {*/
/*    content: "";*/
/*  }*/
/*  .standard-coaches-feedback-tbl td:nth-of-type(5):before {*/
/*    content: "";*/
/*  }*/
/*  .standard-improvement-ideas-tbl td:nth-of-type(1):before {*/
/*    content: "Date";*/
/*  }*/
/*  .standard-improvement-ideas-tbl td:nth-of-type(2):before {*/
/*    content: "Innovation / Improvement Idea";*/
/*  }*/
/*  .standard-improvement-ideas-tbl td:nth-of-type(3):before {*/
/*    content: "Suggested By";*/
/*  }*/
/*  .standard-improvement-ideas-tbl td:nth-of-type(4):before {*/
/*    content: "Approved";*/
/*  }*/
/*  .standard-improvement-ideas-tbl td:nth-of-type(5):before {*/
/*    content: "Status";*/
/*  }*/
/*  .standard-improvement-ideas-tbl td:nth-of-type(6):before {*/
/*    content: "";*/
/*  }*/
/*  .standard-improvement-ideas-tbl td:nth-of-type(7):before {*/
/*    content: "";*/
/*  }*/
/*  .standard-value-behaviours-behaviours-tbl td:nth-of-type(1):before {*/
/*    content: "Agreed Behaviours";*/
/*  }*/
/*  .standard-value-behaviours-behaviours-tbl td:nth-of-type(2):before {*/
/*    content: "";*/
/*  }*/
/*  .standard-value-behaviours-consequences-tbl td:nth-of-type(1):before {*/
/*    content: "Agreed Consequences";*/
/*  }*/
/*  .standard-value-behaviours-consequences-tbl td:nth-of-type(2):before {*/
/*    content: "";*/
/*  }*/
/*  .standard-value-behaviours-tbl td:nth-of-type(1):before {*/
/*    content: "Date";*/
/*  }*/
/*  .standard-value-behaviours-tbl td:nth-of-type(2):before {*/
/*    content: "Observation/Recognition";*/
/*  }*/
/*  .standard-value-behaviours-tbl td:nth-of-type(3):before {*/
/*    content: "Who";*/
/*  }*/
/*  .standard-value-behaviours-tbl td:nth-of-type(4):before {*/
/*    content: "Nominated by";*/
/*  }*/
/*  .standard-value-behaviours-tbl td:nth-of-type(5):before {*/
/*    content: "";*/
/*  }*/
/*  .standard-users-tbl td:nth-of-type(1):before {*/
/*    content: "Last Name";*/
/*  }*/
/*  .standard-users-tbl td:nth-of-type(2):before {*/
/*    content: "First Name(s)";*/
/*  }*/
/*  .standard-users-tbl td:nth-of-type(3):before {*/
/*    content: "E-mail";*/
/*  }*/
/*  .standard-users-tbl td:nth-of-type(4):before {*/
/*    content: "Mobile Number";*/
/*  }*/
/*  .standard-users-tbl td:nth-of-type(5):before {*/
/*    content: "User Role";*/
/*  }*/
/*  .standard-users-tbl td:nth-of-type(6):before {*/
/*    content: "";*/
/*  }*/
/*  .standard-goal-cat-tbl td:nth-of-type(1):before {*/
/*    content: "Goal Category Description";*/
/*  }*/
/*  .standard-goal-tbl td:nth-of-type(1):before {*/
/*    content: "Measurement";*/
/*  }*/
/*  .standard-goal-tbl td:nth-of-type(2):before {*/
/*    content: "Units";*/
/*  }*/
/*  .standard-goal-tbl td:nth-of-type(3):before {*/
/*    content: "Frequency";*/
/*  }*/
/*  .standard-goal-tbl td:nth-of-type(4):before {*/
/*    content: "From";*/
/*  }*/
/*  .standard-goal-tbl td:nth-of-type(5):before {*/
/*    content: "To";*/
/*  }*/
/*  .standard-goal-tbl td:nth-of-type(6):before {*/
/*    content: "By";*/
/*  }*/
/*  .standard-goal-tbl td:nth-of-type(7):before {*/
/*    content: "";*/
/*  }*/
/*  .standard-units-tbl td:nth-of-type(1):before {*/
/*    content: "Units Description";*/
/*  }*/
/*  .standard-measurement-tbl td:nth-of-type(1):before {*/
/*    content: "Goal Category";*/
/*  }*/
/*  .standard-measurement-tbl td:nth-of-type(2):before {*/
/*    content: "Measurement";*/
/*  }*/
/*  .standard-measurement-tbl td:nth-of-type(3):before {*/
/*    content: "Unit";*/
/*  }*/
/*  .standard-measurement-tbl td:nth-of-type(4):before {*/
/*    content: "";*/
/*  }*/
/*  .standard-value-tbl td:nth-of-type(1):before {*/
/*    content: "Value";*/
/*  }*/
/*  .standard-value-tbl td:nth-of-type(2):before {*/
/*    content: "Definition";*/
/*  }*/
/*  .standard-team-type-tbl td:nth-of-type(1):before {*/
/*    content: "Team Type Description";*/
/*  }*/
/*  .standard-team-type-tbl td:nth-of-type(2):before {*/
/*    content: "Type Level";*/
/*  }*/
/*  .standard-team-type-tbl td:nth-of-type(3):before {*/
/*    content: "";*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 620px) and (orientation:landscape) and (orientation:portrait) {*/
/*  .standard-tbl td {*/
/*    padding-left: 200px;*/
/*    word-break: break-word;*/
/*  }*/

/*  .standard-tbl td:before {*/
/*    width: 130px !important;*/
/*  }*/
/*}*/

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-brand {
  z-index: 99999;
}

.menu {
  background-color: #005e85 !important;
  height: auto !important;
  align-items: center !important;
  justify-content: flex-end;
  z-index: 7777;
}

/*@media only screen and (max-width: 991px) and (orientation:portrait) and (orientation:landscape) {*/
/*  .dropdown-item {*/
/*    display: flex;*/
/*    width: 100%;*/
/*    padding: .25rem 1.5rem;*/
/*    clear: both;*/
/*    font-weight: 400;*/
/*    color: #fff !important;*/
/*    text-align: inherit;*/
/*    white-space: nowrap;*/
/*    background-color: transparent;*/
/*    border: 0;*/
/*    justify-content: center;*/
/*  }*/
/*  .dropdown-menu.show {*/
/*    background-color: #005e85 !important;*/
/*    padding: 0 0 !important;*/
/*    height: auto !important;*/
/*    width: auto !important;*/
/*    align-items: center !important;*/
/*    border: none !important;*/
/*  }*/
/*  .dropdown-item {*/
/*    color: #fff !important;*/
/*    align-items: self-start !important;*/
/*  }*/
/*  .dropdown-item:hover, .dropdown-item:active, .dropdown-item:focus {*/
/*    color: #fff!important;*/
/*    background-color: transparent!important;*/
/*    box-shadow: 0px 0px 0px transparent!important;*/
/*    border-color: transparent!important;*/
/*  }*/
/*  .dropdown-toggle.menu-styles.nav-link.pill-menu.dropdown.nav-item::after{*/
/*    display: inline-block;*/
/*    margin-left: .255em;*/
/*    vertical-align: .255em;*/
/*    content: normal;*/
/*    border-top: .3em solid;*/
/*    border-right: .3em solid transparent;*/
/*    border-bottom: 0;*/
/*    border-left: .3em solid transparent;*/
/*  }*/
/*  .dropdown-toggle.menu-styles.nav-link.dropdown.nav-item::after{*/
/*    display: inline-block;*/
/*    margin-left: .255em;*/
/*    vertical-align: .255em;*/
/*    content: normal;*/
/*    border-top: .3em solid;*/
/*    border-right: .3em solid transparent;*/
/*    border-bottom: 0;*/
/*    border-left: .3em solid transparent;*/
/*  }*/
/*  .dropdown-toggle:after {*/
/*    display: inline-block;*/
/*    margin-left: .255em;*/
/*    vertical-align: .255em;*/
/*    !*content: "";*!*/
/*    content: normal;*/
/*    border-top: .3em solid;*/
/*    border-right: .3em solid transparent;*/
/*    border-bottom: 0;*/
/*    border-left: .3em solid transparent;*/
/*  }*/
/*  .nav-link {*/
/*    color: #fff;*/
/*    font-weight: 600;*/
/*    font-size: 18px;*/
/*    text-transform: uppercase;*/
/*  }*/

/*  .nav-item.active .nav-link { color: #2fa753!important;*/
/*    font-weight: 600;*/
/*  }*/
/*  .pill-menu {*/
/*    color: #fff!important;*/
/*    background-color: #005e85!important;*/
/*    border-radius: 21px;*/
/*    border-color: #005e85!important;*/
/*  }*/
/*  .pill-menu:hover, .pill-menu:active, .pill-menu:focus {*/
/*    color: #fff!important;*/
/*    background-color: #005e85!important;*/
/*  }*/

/*  .scroller {*/
/*    overflow: auto !important;*/
/*  }*/
/*  .eyecatcher {*/
/*    opacity: 1 !important;*/
/*    max-height: 550px !important;*/
/*    height: 100% !important;*/
/*    width: 100% !important;*/
/*    user-select: none !important;*/
/*    pointer-events: unset !important;*/
/*    touch-action: none !important;*/
/*    position: relative !important;*/
/*    overflow: auto !important;*/
/*    object-fit: cover !important;*/
/*  }*/

/*  .eyecatcher {*/
/*    overflow-y: hidden !important;*/
/*  }*/

/*  svg {*/
/*    width: auto !important;*/
/*    max-height: 500px;*/
/*  }*/
/*  element.style {*/

/*  }*/

/*  .standard-modal-skill-matrix {*/
/*    width: 35% !important;*/
/*  }*/

/*  .goog-te-combo {*/
/*    height: calc(1.5em + .375rem + 1.5px)!important;*/
/*    !*color: #fff!important;*!*/
/*    !*background-color: #005e85!important;*!*/
/*    !*border: 1px solid #005e85!important;*!*/
/*  }*/
/*  .goog-te-gadget .goog-te-combo {*/
/*     margin: 0 0;*/
/*  }*/
/*}*/


.menu-navbar {
  width: 100%;
  padding: 0 0;
  margin: 0 0 !important;
  height: auto !important;
  align-items: center !important;
  position: absolute;
  top: 22px;
  left: 0;
}
.menu-col {
padding: 0 0;
margin: 0 0;
}


.project-titles {
  font-weight: bold;
}
.tracker-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/*@media only screen and (max-width: 950px) and (orientation:portrait) {*/
/*  .padding-btn {*/
/*    padding: .375rem 0!important;*/
/*  }*/
/*  .tracker {*/
/*    flex-direction: row;*/
/*    width: auto !important;*/
/*    margin-left: 0 !important;*/
/*  }*/
/*    .spacingOffSet {*/
/*        margin-left: 16.666667% !important;*/
/*        margin-right: 12.666667%;*/
/*    }*/
/*}*/

/*@media only screen and (max-width: 1300px) and (orientation:landscape) and (orientation:portrait) {*/
/*  .tracker {*/
/*    flex-direction: row !important;*/
/*    width: 100vw !important;*/
/*    margin-left: 0 !important;*/
/*  }*/
/*  .width-scroller {*/
/*    width: 100vw !important;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 750px) and (orientation:landscape) and (orientation:portrait) {*/
/*  .text-left {*/
/*    text-align: left!important;*/
/*  }*/
/*  .direction-btn {*/
/*    flex-direction: column !important;*/
/*  }*/
/*  .padding-btn {*/
/*    padding: 6px 12px !important;*/
/*    width: unset !important;*/
/*  }*/
/*  .column-tbl {*/
/*    flex-direction: column !important;*/
/*  }*/
/*  .scroller-lng-tbl {*/
/*    overflow: auto;*/
/*  }*/
/*  .standard-modal-scheduled-events {*/
/*    width: 40% !important;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 650px) and (orientation:landscape) and (orientation:portrait) {*/
/*  .standard-tbl td {*/
/*    border: none;*/
/*    border-bottom: 1px solid #dee2e6;*/
/*    position: relative;*/
/*    padding-left: 280px;*/
/*    min-height: 39px;*/
/*    width: 100%!important;*/
/*  }*/
/*  .standard-tbl td:before {*/
/*    width: 150px!important;*/
/*    white-space: inherit!important;*/
/*  }*/

/*  .standard-modal-skill-matrix {*/
/*    width: 50% !important;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 600px) and (orientation:landscape) and (orientation:portrait) {*/
/*  .team-col {*/
/*    justify-content: flex-start !important;*/
/*    flex-direction: column !important;*/
/*  }*/
/*  .scroller-scheduled {*/
/*    overflow: auto;*/
/*  }*/
/*  .legend-bottom {*/
/*    bottom: 80px !important;*/
/*  }*/
/*  .standard-modal-scheduled-events {*/
/*    width: 50% !important;*/
/*  }*/
/*  footer {*/
/*    height: 158px!important;*/
/*  }*/
/*  #cdiAppContainer {*/
/*    margin-bottom: 100px;*/
/*    padding-bottom: 10rem!important;*/
/*  }*/
/*  #root {*/
/*    height: 100%;*/
/*    margin-bottom: 100px;*/
/*    padding-bottom: 10rem!important;*/
/*  }*/
/*  .fixed-bottom {*/
/*    bottom: 158px!important;*/
/*  }*/
/*  .leadership {*/
/*    margin: 1.5rem 1.5rem 55px 1.5rem!important;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 450px) and (orientation:landscape) and (orientation:portrait) {*/
/*  .legend {*/
/*    flex-direction: column;*/
/*  }*/
/*  .form-screen {*/
/*    height: auto !important;*/
/*    flex-direction: column !important;*/
/*  }*/
/*  .standard-tbl td:before {*/
/*    width: 150px!important;*/
/*  }*/
/*  .standard-tbl td {*/
/*    padding-left: 180px!important;*/
/*  }*/

/*  .standard-modal-skill-matrix {*/
/*    width: 75% !important;*/
/*    flex-direction: column !important;*/
/*  }*/


/*  .leadership {*/
/*    margin: 1.5rem 1.5rem 60px 1.5rem!important;*/
/*  }*/


/*  .paddingForScreenBottom {*/
/*    padding-bottom: 6rem!important;*/
/*  }*/
/*}*/

.coaching-rev-scroller {
  overflow: auto;
}

.standard-modal {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}
.standard-modal-scheduled-events {
  width: 30%;
}
.standard-modal-modules {
  width: 40%;
}
.standard-modal-task-category {
  width: 33%;
}
.standard-modal-skill-matrix {
  width: 20%;
}
.standard-modal-individual-results {
  width: 500px;
  height: 70%;
}

.btn-close {
  display: flex;
  justify-content: flex-end !important;
}

.loader-btn {
  display: flex;
  justify-content: center !important;
  padding: 0 0 !important;
  transform: scale(0.4);
}

.include-users-content {
  max-height: 230px !important;
  overflow-y: auto;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 9999999 !important;
}

.include-users-content {
  max-height: 300px;
  overflow-y: auto;
}

.menu-styles {
  text-align: center;
}

.google-trans {
  display: flex;
  justify-content: center;
}

.stand-in-owner {
  vertical-align: baseline;
}

.spacingOffSet {
    margin-left: 12.666667%;
}
.leadership {
  margin: 3rem 1.5rem 47px 1.5rem;
}

.vsp-title {
  justify-content: center;
  display: flex;
  margin-top: 202px;
  padding: 0 0;
  align-items: center;
}

.vsp-titles {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  text: center;
  align: center;
  justify: center;
}

.paddingForScreenBottom {
  padding-bottom: 3rem;
}

.mlm {
  display: flex;
  justify-content: flex-end;
}

.paddingType {
  padding-left: 0;
  padding-right: 0;
}
.user-name {
font-weight: 600;
}

.lightbulb {
  width: 42%;
  height: 200%;
  margin: 0 0 5px 0;
}
.lightbulb-white {
  width: 42%;
  height: 150%;
  margin: 0 0 3px 0;
}
.backBtn {
  width: 8px;
  margin: 0px 0 4px 0;
}
.backBtn-container {
  width: 47.61px;
  height: 38px;
}
.cdi-blue-txt-1 {
  margin-left: 20px;
  height: 35px;
  width: 75.61px;
  color: #005e85;
  font-weight: 600;
}

.vsp-space {
  margin-left: -50px;
}
.vsp-table-space {
  margin-left: -30px;
}

.noRow {
  display: flex;
  flex-wrap: nowrap !important;
  margin-right: 0;
  margin-left: 0 !important;
  justify-content: flex-end;
}

.childenSpace {
  padding: 0;
  align-content: center;
  display: contents;
}

.result-title-row {
  background-color: #dadada;
  padding: 8px 10px 7px 10px;
  margin: auto 0;
  height: 55px;
}

.btn-text-Wht {
  color: #fff;
}

.refreshBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}


.line-btn {
  display: flex;
  justify-content: flex-end;
}
.copyright {
  font-weight: lighter;
  font-size: 12px;
  text-transform: uppercase;
  padding: 0 !important;
  margin: 0 !important;
}

.copyIcon {
  font-weight: bold;
  font-size: 11px;
  text-transform: uppercase;
  padding-left: 10px;
  margin-left: 10px;
}

.welcome-arrows {
    display: inline-block !important;
    height: .9em !important;
    overflow: visible !important;
    vertical-align: baseline !important;
    z-index: 10 !important;
    color: darkgrey !important;
    width: 0.9em !important;
    font-size: 3em !important;
}

.welcome-arrows-container {
  display: grid;
  justify-content: center;
  align-items: center;
}
.row {
  display: flex;
  flex-wrap: wrap;
   margin-right: 0 !important;
  margin-left: -15px;
}

.pt-overflow-container {
  max-width: 250px;
  width: 250px;
  max-height: 40px;
  white-space: nowrap;
  display: block;
   display: -webkit-box;
   margin: 0 auto;
   line-height: 1.3;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
}

.mile-proj {
  display: flex;
  text-align: left;
  overflow-wrap: break-word;
  white-space: normal;
}
.result-container-fluid, .result-container-lg, .result-container-md, .result-container-sm, .result-container-xl {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: .9rem !important;
  padding-left: .9rem !important;
}
.show-up {
  z-index: 7778;
  position: absolute;
  padding: 0 0 0 140px;
  display: flex;
}

.footer-container {
  align-items: center;
  display: grid;
}
.menu-bar {
  display: contents;
}
.users-col {
  min-width: 130px !important;
  max-width: 130px !important;
}

.structure-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  max-width: 640px;
  width: 640px;
  /*min-width: 470px;*/
}
.structure-department {
  width: 280px;
  max-width: 280px;
}
.structure-team-type {
  width: 240px;
  max-width: 240px;
  text-align: left;
}
.structure-shift {
  width: 240px;
  max-width: 240px;
}
.structure-btn {
  width: 240px;
  max-width: 240px;
}

@media only screen and (max-width: 1200px) and (orientation:landscape) {
  .structure-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    max-width: 600px !important;
    width: 600px !important;
    /*min-width: 470px !important;*/
  }
  .structure-department {
    width: 280px !important;
    max-width: 280px !important;
  }
  .structure-team-type {
    width: 190px !important;
    max-width: 190px !important;
    text-align: left !important;
  }
  .structure-shift {
    width: 190px !important;
    max-width: 190px !important;
  }
  .structure-btn {
    width: 190px !important;
    max-width: 190px !important;
  }
}

@media only screen and (max-width: 1160px) and (orientation:landscape) {
  .structure-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    max-width: 450px !important;
    width: 450px !important;
    /*min-width: 470px !important;*/
  }
  .structure-department {
    width: 280px !important;
    max-width: 280px !important;
  }
  .structure-team-type {
    width: 140px !important;
    max-width: 140px !important;
    text-align: left !important;
  }
  .structure-shift {
    width: 140px !important;
    max-width: 140px !important;
  }
  .structure-btn {
    width: 140px !important;
    max-width: 140px !important;
  }
}

@media only screen and (max-width: 991px) and (orientation:landscape) {
  .structure-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    max-width: 840px !important;
    width: 840px !important;
    /*min-width: 470px !important;*/
  }
  .structure-department {
    width: 280px !important;
    max-width: 280px !important;
  }
  .structure-team-type {
    width: 280px !important;
    max-width: 280px !important;
    text-align: left !important;
  }
  .structure-shift {
    width: 280px !important;
    max-width: 280px !important;
  }
  .structure-btn {
    width: 280px !important;
    max-width: 280px !important;
  }
}

@media only screen and (max-width: 750px) and (orientation:landscape) {
  .structure-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    max-width: 600px !important;
    width: 600px !important;
    /*min-width: 470px !important;*/
  }
  .structure-department {
    width: 280px !important;
    max-width: 280px !important;
  }
  .structure-team-type {
    width: 190px !important;
    max-width: 190px !important;
    text-align: left !important;
  }
  .structure-shift {
    width: 190px !important;
    max-width: 190px !important;
  }
  .structure-btn {
    width: 190px !important;
    max-width: 190px !important;
  }
}

@media only screen and (max-width: 750px) and (orientation:landscape) {
  .structure-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    max-width: 600px !important;
    width: 600px !important;
    /*min-width: 470px !important;*/
  }
  .structure-department {
    width: 280px !important;
    max-width: 280px !important;
  }
  .structure-team-type {
    width: 190px !important;
    max-width: 190px !important;
    text-align: left !important;
  }
  .structure-shift {
    width: 190px !important;
    max-width: 190px !important;
  }
  .structure-btn {
    width: 190px !important;
    max-width: 190px !important;
  }
}

@media only screen and (max-width: 660px) and (orientation:landscape) {
  .structure-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    max-width: 450px !important;
    width: 450px !important;
    /*min-width: 470px !important;*/
  }
  .structure-department {
    width: 280px !important;
    max-width: 280px !important;
  }
  .structure-team-type {
    width: 140px !important;
    max-width: 140px !important;
    text-align: left !important;
  }
  .structure-shift {
    width: 140px !important;
    max-width: 140px !important;
  }
  .structure-btn {
    width: 140px !important;
    max-width: 140px !important;
  }
}

@media only screen and (max-width: 650px) and (orientation:landscape) {
  .structure-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    max-width: 1149px !important;
    width: 1149px !important;
    /*min-width: 470px !important;*/
  }
  .structure-department {
    width: 300px !important;
    max-width: 300px !important;
  }
  .structure-team-type {
    width: 350px !important;
    max-width: 350px !important;
    text-align: left !important;
  }
  .structure-shift {
    width: 350px !important;
    max-width: 350px !important;
  }
  .structure-btn {
    width: 350px !important;
    max-width: 350px !important;
  }
}

@media only screen and (max-width: 450px) and (orientation:landscape) {
  .structure-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    max-width: 840px !important;
    width: 840px !important;
    /*min-width: 470px !important;*/
  }
  .structure-department {
    width: 280px !important;
    max-width: 280px !important;
  }
  .structure-team-type {
    width: 280px !important;
    max-width: 280px !important;
    text-align: left !important;
  }
  .structure-shift {
    width: 280px !important;
    max-width: 280px !important;
  }
  .structure-btn {
    width: 280px !important;
    max-width: 280px !important;
  }
}

@media only screen and (max-width: 380px) and (orientation:landscape) {
  .structure-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    max-width: 600px !important;
    width: 600px !important;
    /*min-width: 470px !important;*/
  }
  .structure-department {
    width: 280px !important;
    max-width: 280px !important;
  }
  .structure-team-type {
    width: 190px !important;
    max-width: 190px !important;
    text-align: left !important;
  }
  .structure-shift {
    width: 190px !important;
    max-width: 190px !important;
  }
  .structure-btn {
    width: 190px !important;
    max-width: 190px !important;
  }
}

.our-leader-container {
  position: relative;
  box-sizing: border-box;
  z-index: 15;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}


.company-name {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
}


.form-group {
  margin: 1rem 0;
}

.btn-ns {
  background-color: transparent;
}

.btn-icons {
  width: 32px;
  border-radius: 50% !important;
  border: 1px solid transparent !important;
  outline: none !important;
}

.btn-icons:focus-visible:active:focus:after{
  outline: none !important;
}
.btn-icons-container {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  width: 32px;
  height: 32px;
  padding: 0 !important;
  margin-left: 5px;
  border-radius: 50% !important;
  outline: none !important;
}

.btn-icons-container:focus-visible:active:focus:after{
  outline: none !important;
}

.loader-spinner {
  width: 32px;
  height: 32px;
  padding: 0 !important;
  -webkit-animation:spin 2s linear infinite;
  -moz-animation:spin 2s linear infinite;
  animation:spin 2s linear infinite;
  outline: none !important;
}

.date-selector-margin {
  margin-top: 0px;
}

.loader-spinner:focus-visible:active:focus:after{
  outline: none !important;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.btn-scheduled {
  width: 75px;
  height: 40px;
}

@media only screen and (max-width: 1300px) {
  .pt-overflow-container {
    max-width: 150px;
    width: 150px;
  }
}

@media only screen and (max-width: 1100px) {
  .date-selector-margin {
    margin-top: 120px!important;
  }
}

@media only screen and (max-width: 900px) {
  .pt-overflow-container {
    max-width: 100px;
    width: 100px;
  }
}

.pagination_container {
  display: flex;
  justify-content: center;
}

/* CSS for Removing */
.hide-track::-webkit-scrollbar {
  display: none;
}

/* CSS For Pagination */
.pagination .page-item .page-link {
  border-color: #005e85!important;
  color: #005e85!important;
}
.pagination .active .page-link {
  color: white !important;
  background-color: #005e85!important;
}


/* Calendar - Invited Background */
.invited-style {
  --dot-bg: black;
  --dot-color: white;
  --dot-size: 1px;
  --dot-space: 8px;
  background:
          linear-gradient(90deg, var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
          linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
          var(--dot-color);
}

/* Team Result Not Clicking */
g[clip-path="url(#victory-clip-5)"], g[clip-path="url(#victory-clip-4)"] {
  pointer-events: none;
}

/* Style For Highlighted Next Step */
.bg-highlight {
  background-color: rgba(0, 0, 0, 0.15) !important;
  border: 2px solid rgba(0, 0, 0, 0.25) !important;
}

.bg-highlight-light {
  background-color: rgba(0, 0, 0, 0.10) !important;
  border: 2px solid rgba(0, 0, 0, 0.15) !important;
}

/* CR Header */
.cr-header .dropdown-toggle::after {
    border-top: .7em solid white;
    border-right: .7em solid transparent;
    border-left: .7em solid transparent;
    margin-left: 1.25em;
}